import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Blogs from "./Blog/Blogs";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import Login from "./pages/Login";
//usuarios
import Editusers from "./Users/Editusers";
import Addusers from "./Users/Addusers";
import Users from "./Users/Users";
import Editpassword from "./Users/Editpassword";
//facturas
import Facturas from "./Facturas/Facturas";
import Addfactura from "./Facturas/Addfactura";
import Editfactura from "./Facturas/Editfactura";
//tareas
import Tareas from "./Tareas/Tareas";
import Addtareas from "./Tareas/Addtareas";
import Edittareas from "./Tareas/Edittareas";

//cuentas
import Cuenta from "./Cuenta/Cuenta";
import Addcuenta from "./Cuenta/Addcuenta";
import Editcuenta from "./Cuenta/Editcuenta";
//Blogs
import Addpost from "./Blog/Addpost";
import Editblog from "./Blog/Editblog";
import Colabora from "./Colaborador/Colabora";
//colaboradores
import Addcol from "./Colaborador/Addcol";
import Editcol from "./Colaborador/Editcol";
//mensajes
import Message from "./Message/Message";
import AllMessage from "./Message/AllMessage";

// Alumnos
import Alumno from "./Alumno/Alumno";
import Addalumno from "./Alumno/Addalumno";
import Editalumno from "./Alumno/Editalumno";

//cursos
import Curso from "./Curso/Curso";
import Addcurso from "./Curso/Addcurso";
import Editcurso from "./Curso/Editcurso";
import Addtarea from "./Curso/Addtarea";
import Adddocumento from "./Curso/Adddocumento";
import Addlink from "./Curso/Addlink";
import EditCursoBaner from "./Curso/EditCursoBaner";
import EditCursoFoto from "./Curso/EditCursoFoto";
import EditCursoBrochure from "./Curso/EditCursoBrochure";
import AddBanersCurso from "./Curso/AddBaners";
import Modulo from "./Curso/Modulos/Modulo";
import AddModulo from "./Curso/Modulos/AddModulos";
import EditModulo from "./Curso/Modulos/EditModulo";
import ModuleConfig from "./Curso/Modulos/ModuloConfig";

//Baner
import Baner from "./Baner/Baner";
import Addbaner from "./Baner/Addbaner";
import EditBaner from "./Baner/Editbaner";
//Normativa
import Normativa from "./Normativa/Normativa";
import Addnorma from "./Normativa/Addnormativa";
import Editnorma from "./Normativa/Editnormativa";
import Editnormativacard from "./Normativa/Editnormativacard";
import Editnormativaimagen from "./Normativa/Editnormativaimagen";
import Editnormativapdf from "./Normativa/Editnormativapdf";

//Matricula
import Matricula from "./Matricula/Matricula";
import Addmatricula from "./Matricula/Addmatricula";
import Editmatricula from "./Matricula/Editmatricula";
import Pre_inscripcion from "./preinscripcion/preinscri";
//webinar
import Webinar from "./webinar/webinar";
import Addwebinar from "./webinar/addwebinar";
import Editwebinar from "./webinar/editwebinar";
//universidad
import Universidad from "./Univercidades/Universidades";
import EditUniversidad from "./Univercidades/editUniversidad";
import AddUniversidad from "./Univercidades/addUniversidad";
//departamentos
import Departamentos from "./departamentos/departamentos";
import AddDepartamentos from "./departamentos/adddepartamentos";
import EditDepartamentos from "./departamentos/editdepartamentos";
//usuarios torneo
import Torneo from "./torneos/torneo";
import AddTorneo from "./torneos/addTorneo";
import AddNovedadesTorneo from "./torneos/addNovedades";
import EditTorneo from "./torneos/editTorneo";
import EditTiktok from "./torneos/edittiktok";
//terminos
import Terminos from "./Terminos/Terminos";
import AddTermino from "./Terminos/addTerminos";
import EditTermino from "./Terminos/editTermino";

const ProtectedRoute = ({}) => {
  const location = useLocation();
  const currentPath = location.pathname;

  if (sessionStorage.getItem("userName") == null) {
    return <Navigate to={"/login"} replace />;
  }

  if (currentPath != "/") {
    if (sessionStorage.getItem("role") === "Docente") {
      const rutas = [
        "Alumno",
        "Addalumno",
        "Editalumno/",
        "Curso",
        "Addcurso",
        "Editcurso/",
        "EditCursoFoto/",
        "EditCursoBaner/",
        "EditCursoBrochure/",
        "Addtarea/",
        "Adddocumento/",
        "Addlink/",
        "Tareas",
        "Addtareas",
        "Edittareas/",
        "Matricula",
        "Addtareas",
        "Addmatricula",
        "Editmatricula/",
        "Editnormativacard/",
        "Editnormativaimagen/",
      ];

      if (
        rutas.filter((m) => currentPath.replace("/", "").startsWith(m))
          .length == 0
      ) {
        return <Navigate to={""} replace />;
      }
    }
  }
  return <Layout />;
};

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route path="/" element={<ProtectedRoute />}>
          <Route index element={<Home />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="Addpost" element={<Addpost />} />
          <Route path="Editblog/:id" element={<Editblog />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} />

          <Route path="Users" element={<Users />} />
          <Route path="Editusers/:id" element={<Editusers />} />
          <Route path="Editpassword/:id" element={<Editpassword />} />
          <Route path="Addusers" element={<Addusers />} />

          <Route path="Facturas" element={<Facturas />} />
          <Route path="Addfactura" element={<Addfactura />} />
          <Route path="Editfactura/:id" element={<Editfactura />} />

          <Route path="Tareas" element={<Tareas />} />
          <Route path="Addtareas" element={<Addtareas />} />
          <Route path="Edittareas/:id" element={<Edittareas />} />

          <Route path="Cuenta" element={<Cuenta />} />
          <Route path="Addcuenta" element={<Addcuenta />} />
          <Route path="Editcuenta/:id" element={<Editcuenta />} />

          <Route path="Colabora" element={<Colabora />} />
          <Route path="Addcol" element={<Addcol />} />
          <Route path="Editcol/:id" element={<Editcol />} />

          <Route path="Message" element={<Message />} />
          <Route path="Allmessage/:id" element={<AllMessage />} />

          <Route path="Alumno" element={<Alumno />} />
          <Route path="Addalumno" element={<Addalumno />} />
          <Route path="Editalumno/:id" element={<Editalumno />} />

          <Route path="Curso" element={<Curso />} />
          <Route path="Addcurso" element={<Addcurso />} />
          <Route path="Editcurso/:id" element={<Editcurso />} />
          <Route path="Addtarea/:id" element={<Addtarea />} />
          <Route path="Adddocumento/:id" element={<Adddocumento />} />
          <Route path="Addlink/:id" element={<Addlink />} />
          <Route path="EditCursoFoto/:id" element={<EditCursoFoto />} />
          <Route path="EditCursoBaner/:id" element={<EditCursoBaner />} />
          <Route path="EditCursoBrochure/:id" element={<EditCursoBrochure />} />
          <Route path="addBanerCurso/:id" element={<AddBanersCurso />} />
          <Route path="Modulo/:id" element={<Modulo />} />
          <Route path="AddModulo/:cursoId/:sesionId" element={<AddModulo />} />
          <Route path="editModulo/:id/:cursoid" element={<EditModulo />} />
          <Route
            path="contenido/curso/:id/:cursoid"
            element={<ModuleConfig />}
          />

          <Route path="Baner" element={<Baner />} />
          <Route path="Addbaner" element={<Addbaner />} />
          <Route path="Editbaner/:id" element={<EditBaner />} />

          <Route path="Normativa" element={<Normativa />} />
          <Route path="Addnorma" element={<Addnorma />} />
          <Route path="Editnorma/:id" element={<Editnorma />} />
          <Route path="Editnormativacard/:id" element={<Editnormativacard />} />
          <Route
            path="Editnormativaimagen/:id"
            element={<Editnormativaimagen />}
          />
          <Route path="Editnormativapdf/:id" element={<Editnormativapdf />} />

          <Route path="Matricula" element={<Matricula />} />
          <Route path="Addmatricula" element={<Addmatricula />} />
          <Route path="Editmatricula/:id" element={<Editmatricula />} />

          <Route path="/webinar" element={<Webinar />} />
          <Route path="/addwebinar" element={<Addwebinar />} />
          <Route path="/editwebinar/:id" element={<Editwebinar />} />

          <Route path="/Universidad" element={<Universidad />} />
          <Route path="/AddUniversidad" element={<AddUniversidad />} />
          <Route path="/EditUniversidad/:id" element={<EditUniversidad />} />

          <Route path="/Departamentos" element={<Departamentos />} />
          <Route path="/AddDepartamento" element={<AddDepartamentos />} />
          <Route path="/EditDepartamento/:id" element={<EditDepartamentos />} />

          <Route path="/Torneos" element={<Torneo />} />
          <Route path="/AgregarTorneo" element={<AddTorneo />} />
          <Route
            path="/AgregarTorneo/Novedad/:id"
            element={<AddNovedadesTorneo />}
          />
          <Route path="/EditarTorneo/:id" element={<EditTorneo />} />
          <Route path="/editar/tiktok/:id" element={<EditTiktok />} />

          <Route path="Terminos" element={<Terminos />} />
          <Route path="AddTerminos" element={<AddTermino />} />
          <Route path="EditTerminos/:id" element={<EditTermino />} />

          <Route path="inscripcion" element={<Pre_inscripcion />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
